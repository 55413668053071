<template>
  <a-input-search
    class="ll-search"
    :disabled="disabled || loading"
    :loading="loading"
    :placeholder="placeholder"
    allow-clear
    @input="handleInput"
    @search="handleSearch"
  >
    <template #addonBefore><lander-icon type="search" /></template>
  </a-input-search>
</template>

<script>
export default {
  name: 'LanderSearch',
  props: {
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    placeholder: { type: String, default: 'Search...' },
  },
  methods: {
    handleInput(event) {
      if (!event.target.value) this.$emit('search', '');
    },
    handleSearch(value) {
      if (value) this.$emit('search', value);
    },
  },
};
</script>

<style>

</style>
