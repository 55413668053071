import axios from '@/services/axios';

const api = {
  auth: {
    async login(payload) {
      return axios.post('admin/login', payload);
    },
  },
  users: {
    getAll(payload) {
      return axios.post('admin/users', payload);
    },
    impersonate(id) {
      return axios.post('admin/impersonate', { id });
    },
    updateDemoDiscountStatus(id) {
      return axios.post(`admin/users/${id}/coupon`);
    },
    updateBasicAccessStatus(id) {
      return axios.post(`admin/users/${id}/basicAccess`);
    }
  },
  billing: {
    get({ month, year }) {
      return axios.get('admin/finance/get', { params: { month, year } });
    }
  },
  dashboard: {
    analytics() {
      return axios.get('admin/analytics');
    },
    graphs({ startDate, endDate, timezone }) {
      return axios.get('admin/graphs', { params: { startDate, endDate, timezone } });
    },
    stats({ startDate, endDate, timezone }) {
      return axios.get('admin/stats', { params: { startDate, endDate, timezone } });
    }
  },
  templates: {
    getAll() {
      return axios.get('admin/templates/get');
    },
    create(payload) {
      return axios.post('admin/templates/create', payload);
    },
    update(id, payload) {
      return axios.patch(`admin/templates/${id}/update`, payload);
    },
    updateImage(id, payload) {
      return axios.post(`admin/templates/${id}/updateImage`, payload);
    },
    updateCode(id, payload) {
      return axios.post(`admin/templates/${id}/updateCode`, payload);
    },
    publish(id) {
      return axios.post(`admin/templates/${id}/publish`);
    },
    unpublish(id) {
      return axios.post(`admin/templates/${id}/unpublish`);
    },
    delete(id) {
      return axios.delete(`admin/templates/${id}/delete`);
    }
  },
  editor: {
    sections: {
      getAll() {
        return axios.get('admin/sections/get');
      },
      // search({ page, limit, q }) {
      //   return axios.get('templates/search', { params: { page, limit, q } });
      // },
      create(payload) {
        return axios.post('admin/sections/create', payload);
      },
      update(id, payload) {
        return axios.patch(`admin/sections/${id}/update`, payload);
      },
      publish(id) {
        return axios.post(`admin/sections/${id}/publish`);
      },
      unpublish(id) {
        return axios.post(`admin/sections/${id}/unpublish`);
      },
      delete(id) {
        return axios.delete(`admin/sections/${id}/delete`);
      },
      updateOrder(payload) {
        return axios.post('admin/sections/order/update', payload);
      },
      orderCategories(payload) {
        return axios.post('admin/sections/category/order/update', payload);
      },
      updateCategory(payload) {
        return axios.post('admin/sections/category/rename', payload);
      }
    },
    elements: {
      getAll() {
        return axios.get('admin/elements/get');
      },
      create(payload) {
        return axios.post('admin/elements/create', payload);
      },
      update(id, payload) {
        return axios.patch(`admin/elements/${id}/update`, payload);
      },
      publish(id) {
        return axios.post(`admin/elements/${id}/publish`);
      },
      unpublish(id) {
        return axios.post(`admin/elements/${id}/unpublish`);
      },
      updateOrder(payload) {
        return axios.post('admin/elements/order/update', payload);
      },
      delete(id) {
        return axios.delete(`admin/elements/${id}/delete`);
      },
    },
    popups: {
      getAll() {
        return axios.get('admin/popups/get');
      },
      create(payload) {
        return axios.post('admin/popups/create', payload);
      },
      update(id, payload) {
        return axios.patch(`admin/popups/${id}/update`, payload);
      },
      publish(id) {
        return axios.post(`admin/popups/${id}/publish`);
      },
      unpublish(id) {
        return axios.post(`admin/popups/${id}/unpublish`);
      },
      delete(id) {
        return axios.delete(`admin/popups/${id}/delete`);
      },
    },
    styleManager: {
      getAll() {
        return axios.get('admin/style-manager/get');
      },
      update(payload) {
        return axios.post('admin/style-manager/update', payload);
      }
    }
  },
  landingPages: {
    getAll({ page, limit, q }) {
      return axios.get('admin/landers', { params: { page, limit, q } });
    },
    download(id) {
      return axios.get(`admin/landers/${id}/download`, { responseType: 'blob' });
    }
  },
  domains: {
    getAll() {
      return axios.get('admin/domains');
    },
    getDnsRecords(id) {
      return axios.get(`admin/domains/${id}/get`);
    },
    deleteDnsRecord(domainId, id) {
      return axios.delete(`admin/domains/${domainId}/dnsRecords/${id}/delete`);
    },
    redirectToRoot(id) {
      return axios.post(`admin/domains/${id}/redirect`);
    }
  },
  assets: {
    get() {
      return axios.get('admin/assets/get');
    },
    update(payload) {
      return axios.post('admin/assets/update', payload);
    }
  },
  credits: {
    getAll() {
      return axios.get('admin/credits/get');
    },
    get(id) {
      return axios.get(`admin/credits/${id}/get`);
    },
    update(id, payload) {
      return axios.patch(`admin/credits/${id}/update`, payload);
    },
  },
  feedbacks: {
    get() {
      return axios.get('admin/feedbacks/get');
    }
  },
  cron: {
    templateScreenshots() {
      return axios.get('cronjobs/template/screenshot');
    }
  }
};

const apiProxy = (obj, path = '') => {
  Object.entries(obj).forEach(([key, func]) => { // eslint-disable-line
    if (typeof func !== 'function') return apiProxy(func, `${path}${path ? '.' : ''}${key}`); // func is not a function, deep recursive
    obj[key] = async (...args) => { // eslint-disable-line
      try {
        const result = await func(...args);
        if (process.env.NODE_ENV === 'development') {
          console.log(`API ${path}${path ? '.' : ''}${key}, METHOD: ${result.config.method}, STATUS: ${result.status}, RESULT:`, result);
        }
        return result.data;
      } catch (err) {
        if (process.env.NODE_ENV === 'development') {
          // TODO: uniform error
          if (err.response) {
            console.error(`API ${path}${path ? '.' : ''}${key}, METHOD: ${err.response.config.method}, STATUS: ${err.response.status}, ERROR:`, err);
          } else if (err.request) {
            console.error(`API ${path}${path ? '.' : ''}${key}, EMPTY_RESPONSE, ERROR:`, err);
          } else {
            console.error(`API ${path}${path ? '.' : ''}${key}, CONFIG_ERROR, ERROR:`, err);
          }
        }
        throw err;
      }
    };
  });
  return api;
};

export default apiProxy(api);
