<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import 'ant-design-vue/dist/antd.css';
import '@/styles/main.scss';

export default {
  name: 'App',
};
</script>

<style>

</style>
