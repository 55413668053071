import Vue from 'vue';
import pinia from '@/stores';
import router from '@/router';

import '@/loaders/filters';
import '@/loaders/plugins';
import '@/loaders/components';
import '@/loaders/injections';

import '@/validations';

import App from '@/App.vue';

if (process.env.NODE_ENV === 'development') Vue.config.productionTip = true;
if (process.env.NODE_ENV === 'production') Vue.config.productionTip = false;
if (process.env.VUE_APP_ENV === 'prod') Vue.config.devtools = false;

new Vue({
  router,
  render: (h) => h(App),
  pinia,
}).$mount('#app');
