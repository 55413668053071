import Vue from 'vue';
import Antd, { message } from 'ant-design-vue';
import VueApexCharts from 'vue-apexcharts';
import { PiniaVuePlugin } from 'pinia';
import VueCodemirror from 'vue-codemirror';
import VueNumber from 'vue-number-animation';

Vue.use(Antd);

Vue.use(VueApexCharts);
Vue.component('ApexChart', VueApexCharts);

Vue.use(PiniaVuePlugin);
Vue.use(VueCodemirror);
Vue.use(VueNumber);

message.config({
  duration: 1.2,
});
