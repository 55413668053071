<template>
  <div :class="[`ll-skeleton ll-skeleton--${component}`, { animated }]">
    <component :is="components[type]" :config="config" />
  </div>
</template>

<script>
import CardSkeleton from './components/CardSkeleton.vue';
import TableSkeleton from './components/TableSkeleton.vue';

export default {
  name: 'LanderSkeleton',
  components: { CardSkeleton, TableSkeleton },
  props: {
    type: { type: String, default: 'card' },
    component: { type: String, default: 'landing-pages' },
    animated: { type: Boolean, default: true },
    config: { type: Object },
  },
  data() {
    return {
      components: {
        card: 'CardSkeleton',
        table: 'TableSkeleton',
      },
    };
  },
};
</script>

<style lang="scss">

</style>
