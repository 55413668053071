export default {
  methods: {
    resolveState({ errors, pending, valid }) {
      if (errors[0]) return 'error';
      if (pending) return 'validating';
      if (valid) return 'success';
      return '';
    },
    isValid() {
      return this.$refs.observer.validate();
    },
    resetValidation() {
      this.$refs.observer.reset();
    },
  },
};
