import Vue from 'vue';
import moment from 'moment';
import prettyMs from 'pretty-ms';

import allLanguages from '@/config/lang';

// Format Date
Vue.filter('formatDate', (value) => { if (!value) return ''; return moment(new Date(value)).format('MMM D, YYYY'); });

// Format Date including hour
Vue.filter('formatDateWithHour', (value) => { if (!value) return ''; return moment(new Date(value)).format('MMM D, YYYY h:mm A'); });

// Format Date M D, Y
Vue.filter('formatDateMonthDayYear', (value) => { if (!value) return ''; return moment(new Date(value)).format('MMM D, YYYY'); });

// Format Date including hours and seconds
Vue.filter('formatDateWithHourAndSeconds', (value) => { if (!value) return ''; return moment(new Date(value)).format('MMM D, YYYY h:mm:ss A'); });

// Format Date including hours and seconds
Vue.filter('formatDateFromNow', (value) => { if (!value) return ''; return moment(new Date(value)).fromNow(); });

// Format Number with coma
Vue.filter('formatNumber', ((value) => String(value || 0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')));

// Format Number with percentage
Vue.filter('formatNumberPercentage', (value) => `${(Number(value || 0) * 100).toFixed(2)}%`);

// Format Language
Vue.filter('formatLanguage', (value) => { if (!value) return ''; return allLanguages[value]; });

// Split Editor File Name
Vue.filter('splitFileName', (value) => { if (!value) return ''; if (value.includes('/')) return value.split('/').slice(-1)[0]; return value; });

// Show root as /
Vue.filter('showRootAsSlash', (value) => { if (!value) return ''; if (value === 'root') { return '/'; } return value.replace('root/', '/'); });

// Format milliseconds
Vue.filter('prettyMilliseconds', (value) => prettyMs(value));

// capitalize /
Vue.filter('capitalize', (value) => value.charAt(0).toUpperCase() + value.substring(1).toLowerCase());
