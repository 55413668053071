<template>
  <div class="ll-card">
    <div class="ll-card__header">
      <div  class="ll-card__header__title">
        <template v-if="$slots['title']"><slot name="title" /></template>
        <template v-else>{{ title }}</template>
      </div>
      <slot name="header-extra-content" class="ll-card__header__extra-content" />
    </div>
    <div class="ll-card__body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LanderCard',
  props: {
    title: { type: String, default: '' },
  },
};
</script>

<style lang="scss">
  .ll-card {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 2rem;
      border-bottom: .1rem solid $black-200;

      &__title {
        font-size: 1.8rem;
        line-height: 1.8rem;
        font-weight: 700;
        color: $black-900;
      }
    }
  }
</style>
