export default {
  data() {
    return {
      modal: false,
      loading: false,
    };
  },
  methods: {
    showModal() {
      this.modal = true;
    },
    hideModal() {
      this.modal = false;
    },
  },
};
