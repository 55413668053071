<template>
  <i class="ll-icon" :class="{ stroke }" v-html="iconType"></i>
</template>

<script>
import icons from './icons';

export default {
  name: 'LanderIcon',
  props: {
    type: { type: String, default: '' },
    stroke: { type: Boolean, default: false },
  },
  data() {
    return {
      allIcons: {},
    };
  },
  computed: {
    iconType() {
      return icons[this.type];
    },
  },
};
</script>

<style lang="scss">
.ll-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
