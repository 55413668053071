<template>
  <div class="ll-skeleton__table">
    <div class="ll-skeleton__table__head">
      <div v-for="_, index in config.table.headItems" :key="index" class="ll-skeleton__table__head__item" />
    </div>

    <div class="ll-skeleton__table__body">
      <div v-for="_, index in config.table.bodyItems" :key="index" class="ll-skeleton__table__body__item">
        <CardSkeleton :config="config.card" />
      </div>
    </div>
  </div>
</template>

<script>
import CardSkeleton from './CardSkeleton.vue';

export default {
  name: 'TableSkeleton',
  components: { CardSkeleton },
  props: {
    config: {
      type: Object,
      default: () => (
        {
          table: {
            headItems: 5,
            bodyItems: 5,
          },
          card: {
            direction: 'horizontal',
            rows: {
              start: 2,
              end: 0,
            },
            shape: {
              type: 'square',
              position: 'start',
            },
          },
        }
      ),
    },
  },
};
</script>

<style lang="scss">
  .ll-skeleton__table {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &__head {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: auto;
      // border-bottom: .1rem solid $black-200;

      &__item {
        height: 2rem;
        width: 15%;
        background: linear-gradient(90deg,#f0f0f8 25%,#f4f4fb 37%,#f2f2f9 63%);
        background-size: 400% 100%;
        // animation: lander-skeleton .8s ease infinite;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      // gap: .5rem;

      &__item {
        // background: linear-gradient(90deg,#f0f0f8 25%,#f4f4fb 37%,#f2f2f9 63%);
        // background-size: 400% 100%;
        height: 7.3rem;
        // border-bottom: .1rem solid $black-200;
      }
    }
  }
</style>
