import Vue from 'vue';
import VueRouter from 'vue-router';
import session from '@/services/session';

/* Containers */
const DashboardContainer = () => import('@/containers/Dashboard.vue');
const AuthContainer = () => import('@/containers/Auth.vue');

/* Authentication */
const Login = () => import('@/views/Authentication/Login.vue');

/* Dashboard */
const Dashboard = () => import('@/views/Dashboard/Dashboard/Dashboard.vue');
const Users = () => import('@/views/Dashboard/Users/Users.vue');
// const Billing = () => import('@/views/Dashboard/Billing/Billing.vue');
const LandingPages = () => import('@/views/Dashboard/LandingPages/LandingPages.vue');
const Domains = () => import('@/views/Dashboard/Domains/Domains.vue');
const Templates = () => import('@/views/Dashboard/Templates/Templates.vue');
const Editor = () => import('@/views/Dashboard/Editor/Editor.vue');
const Assets = () => import('@/views/Dashboard/Assets/Assets.vue');
const Logs = () => import('@/views/Dashboard/Logs/Logs.vue');
const Credits = () => import('@/views/Dashboard/Credits/Credits.vue');

// Is Loged in
const isLoggedIn = (to, from, next) => {
  if (session.isAuthenticated()) {
    return next({ name: 'Dashboard' });
  }
  return next();
};

// Is Admin
const isAdmin = (to, from, next) => {
  if (session.isAuthenticated() && session.getUser().role !== 'admin') {
    return next({ name: 'Dashboard' });
  }
  return next();
};

// Not Loged In
const notLoggedIn = (to, from, next) => {
  if (!session.isAuthenticated()) {
    return next({ name: 'Login' });
  }
  return next();
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'DashboardContainer',
    redirect: { name: 'Dashboard' },
    component: DashboardContainer,
    beforeEnter: notLoggedIn,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: 'users',
        name: 'Users',
        component: Users,
      },
      // {
      //   path: 'billing',
      //   name: 'Billing',
      //   component: Billing,
      //   beforeEnter: isAdmin,
      // },
      {
        path: 'landingPages',
        name: 'LandingPages',
        component: LandingPages,
      },
      {
        path: 'domains',
        name: 'Domains',
        component: Domains,
      },
      {
        path: 'templates',
        name: 'Templates',
        component: Templates,
      },
      {
        path: 'credits',
        name: 'Credits',
        component: Credits,
      },
      {
        path: 'editor/:tab?',
        name: 'Editor',
        component: Editor,
        beforeEnter: isAdmin,
      },
      {
        path: 'assets',
        name: 'Assets',
        component: Assets,
        beforeEnter: isAdmin,
      },
      {
        path: 'logs',
        name: 'Logs',
        component: Logs,
      },
    ]
  },
  {
    path: '/auth',
    component: AuthContainer,
    redirect: { name: 'Login' },
    beforeEnter: isLoggedIn,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login,
      },
    ],
  },
  {
    path: '*',
    name: 'Page404',
    redirect: { name: 'Dashboard' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
