<template>
  <div class="content-wrapper__head">
    <!-- Page Title-->
    <div v-if="name" class="content-wrapper__head__heading">
      <h1>{{ name }}</h1>
      <p v-if="total">({{ total }})</p>
    </div>

    <!-- Custom Actions Slot -->
    <div v-if="$slots['custom-actions']" class="content-wrapper__head__actions">
      <div v-if="refresh" class="refresh-icon-container">
        <a-tooltip :title="refreshText" placement="bottom" overlayClassName="ll-tooltip">
          <a-button @click="emitEvent('refresh')" :disabled="refresh.loading" class="ll-btn ll-btn-icon ll-btn-edit" :class="{ refreshing: refresh.refreshing }"><lander-icon type="refresh" stroke /></a-button>
        </a-tooltip>
      </div>
      <slot name="custom-actions" />
    </div>

    <!-- Page Actions -->
    <div v-else-if="actions" class="content-wrapper__head__actions">
      <div v-if="refresh" class="refresh-icon-container">
        <a-tooltip :title="refreshText" placement="bottom" overlayClassName="ll-tooltip">
          <a-button @click="emitEvent('refresh')" :disabled="refresh.loading" class="ll-btn ll-btn-icon ll-btn-edit" :class="{ refreshing: refresh.refreshing }"><lander-icon type="refresh" stroke /></a-button>
        </a-tooltip>
      </div>

      <!-- Extra Action -->
      <slot name="extra-action" />

      <a-button class="ll-btn ll-btn-primary" @click="emitEvent('add')"><a-icon type="plus" v-if="!buttonText" />{{ getButtonText() }}</a-button>
    </div>
  </div>
</template>

<script>
import { emits } from '@/mixins';

export default {
  name: 'PageHead',
  mixins: [emits],
  props: {
    name: { type: String, default: '' },
    total: { type: Number },
    actions: { type: Boolean, default: false },
    buttonText: { type: String },
    refresh: { type: [Object, null], default: () => null },
  },
  methods: {
    convertToSingleString() {
      return this.name.substring(0, this.name.length - 1);
    },
    getButtonText() {
      if (this.buttonText) {
        return this.buttonText;
      }
      return `Add ${this.convertToSingleString()}`;
    },
    refreshText() {
      return this.$options.filters.formatDateFromNow(this.refresh.date);
    },
  },
};
</script>

<style lang="scss">
  .content-wrapper__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    min-height: 4rem;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    &__heading {
      display: flex;
      align-items: flex-end;

      @include media-breakpoint-down(sm) {
        margin-bottom: 2rem;
      }

      h1 {
        font-size: 2rem;
        color: $black-900;
        font-weight: bold;
        margin-right: 1.2rem;
      }

      p {
        font-size: 1.3rem;
        font-weight: 500;
        color: $black-400;
        margin-bottom: .2rem;
      }
    }

    &__actions {
      display: flex;
      gap: 1rem;

      .refresh-icon-container {
        display: flex;
        align-items: center;

        .refreshing {
          i {
            animation: rotate .7s linear infinite;
          }
        }

        .ll-btn-icon {
          background: $black-200;

          svg {
            height: 2rem;
            width: 2rem;
          }
        }
      }

      button {
        display: flex;
        align-items: center;

        i {
          display: flex;
          align-items: center;
          font-size: 1.6rem;
          font-weight: bold;
        }
      }
    }
  }
</style>
