export default {
  methods: {
    emitValue(attr, value) {
      this.$emit(attr, value);
    },
    emitEvent(event) {
      this.$emit(event);
    },
  },
};
