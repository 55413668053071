import jwtDecode from 'jwt-decode';

/* eslint-disable */
class Session {
  constructor() {
    this._onAuthCallbacks = [];
    this._onLogoutCallbacks = [];
    this._token = null;
    this._user = null;
    this.setToken(localStorage.getItem('lander-jwt'));
  }

  /**
   * Verify if user is authenticated
   * @returns {boolean}
   */
  isAuthenticated() {
    return !!this.getToken();
  }

  /**
   * Get user
   * @returns {null|*}
   */
  getUser() {
    return this._user;
  }

  /**
   * Get jwt token
   * @returns {null|*}
   */
  getToken() {
    return this._token;
  }

  /**
   * Set auth jwt token
   * and extract user from it
   * @param token
   */
  setToken(token) {
    this._token = token;
    if (this._token) {
      try {
        this._user = jwtDecode(token);
        localStorage.setItem('lander-jwt', this._token);
        this._emitAuthenticationEvent();
      } catch (err) {
        this._user = null;
        this._token = null;
        localStorage.removeItem('lander-jwt');
      }
    }
  }

  /**
   * Remove authentication
   */
  removeToken() {
    this._token = null;
    this._user = null;
    localStorage.removeItem('lander-jwt');

    this._emitLogoutEvent();
  }

  /**
   * Emit authentication event
   * @private
   */
  _emitAuthenticationEvent() {
    this._onAuthCallbacks.forEach(func => func(this));
  }

  /**
   * Emit logout event
   * @private
   */
  _emitLogoutEvent() {
    this._onLogoutCallbacks.forEach(func => func(this));
  }

  /**
   * Check if user has admin role
   * @returns {boolean}
   */
  isAdmin() {
    return this._user.role.includes('admin')
  }

  /**
   * Get the package that user is subscribed to
   * @returns {Number}
   */
  getAccountType() {
    return this._user.account_type
  }

  /**
   * Add event listener for authentication
   * @param func
   */
  onAuthentication(func) {
    this._onAuthCallbacks.push(func);
  }

  /**
   * Set after logout callback
   * @param func
   */
  onLogout(func) {
    this._onLogoutCallbacks.push(func);
  }
}

export default new Session();
